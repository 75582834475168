import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Heart({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
    >
      <BaseIconPathStyle
        d="M11.9999 21.844L2.4119 11.844C1.56923 11.002 1.01306 9.91603 0.822258 8.74017C0.631457 7.56431 0.815714 6.35822 1.3489 5.29297V5.29297C1.75095 4.48909 2.33827 3.79224 3.06244 3.25985C3.78662 2.72746 4.62694 2.37476 5.51415 2.23082C6.40137 2.08687 7.31009 2.1558 8.16544 2.43191C9.0208 2.70803 9.7983 3.18344 10.4339 3.81897L11.9999 5.38397L13.5659 3.81897C14.2015 3.18344 14.979 2.70803 15.8343 2.43191C16.6897 2.1558 17.5984 2.08687 18.4856 2.23082C19.3729 2.37476 20.2132 2.72746 20.9374 3.25985C21.6615 3.79224 22.2488 4.48909 22.6509 5.29297C23.1834 6.35781 23.3673 7.56318 23.1767 8.73837C22.9861 9.91356 22.4306 10.999 21.5889 11.841L11.9999 21.844Z"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Heart;
